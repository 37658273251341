/*
* Warning: Any change to this file will constitute a breaking change in Nessus.
*/

const getEnvVars = (env = 'production') => {
  let baseUrl = 'https://www.tenable.com';
  if (env === 'local') baseUrl = 'http://localhost:3000';
  if (env === 'dev') baseUrl = 'https://dev.tenable.com';
  if (env === 'staging') baseUrl = 'https://staging.tenable.com';

  return { baseUrl, environment: env };
};

const getFileHashes = async ({ baseUrl, version }) => {
  const response = await fetch(`${baseUrl}/evaluations/api/v1/nessus/${version}/hashes`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data?.message ?? response.statusText);

  return data;
};

const loadJavascript = async ({ resourceFileUrl: url, hash }) => {
  const script = document.createElement('script');
  script.src = url;
  script.integrity = hash;
  script.setAttribute('crossorigin', 'anonymous');
  document.body.appendChild(script);
};

const loadCss = async ({ resourceFileUrl: url, hash }) => {
  const link = document.createElement('link');
  link.href = url;
  link.integrity = hash;
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.crossOrigin = 'anonymous';
  document.querySelector('head').appendChild(link);
};

(async () => {
  try {
    const version = '10.5.0';
    const targetDivSelector = 't-nessus-activation-resource-load';

    const targetDiv = document.getElementById(targetDivSelector);
    const { baseUrl } = getEnvVars(targetDiv.dataset.env);

    const files = await getFileHashes({ baseUrl, version });
    const js = files.find(({ fileName }) => fileName === 'index.js');
    const css = files.find(({ fileName }) => fileName === 'index.css');

    await loadJavascript(js);
    await loadCss(css);
  } catch (error) {
    console.error(`Activation resource load error: ${error}`);
  }
})();
